import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, IsInt, MaxLength, validate } from "class-validator";

import { Header,Footer } from "@/layout";
import { APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { HomeworkModel } from "@/models";
import { TeCommon } from "../Common";

export class PaperForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) name = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) date = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) cid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) sid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) clid = "";
    tid = window.localStorage.getItem('teacher_tid') as string;
    @IsDefined() @IsNotEmpty({ message: "必填" }) grade = "";
    level = "";
    unit = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) num = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = "1";
    token = window.localStorage.getItem('teacherToken') as string;
}

type response = { [key: string]: unknown };

@Component<ApTeacherHomeworkPaperAddController>({
    components: {
        Header, APLightbox,Footer
    }
})
export default class ApTeacherHomeworkPaperAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private paperForm = new PaperForm();
    private cityList: object = {};
    private schoolList: object = {};
    private classList: object = {};
    private gradeList: object = {};
    private levelList: object = {};
    private unitList: response = {};
    private identity = "";
    private name = window.localStorage.getItem('teacher_name') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    private lightbox: { showLB: boolean, errorMsg: string } = {
        showLB: false, 
        errorMsg:  '',
    };

    private errorMsgMap: { [key: string]: string } = {
        name: '',
        date: '',
        cid: '',
        sid: '',
        clid: '',
        unit: '',
        level: '',
        grade: '',
        num: '',
        active: '',
    };

    public async created() {
        this.cityList = await TeCommon.getTECity(this.paperForm.tid); //取得該老師所屬班級的所有縣市
        for (const c of this.cityList as any) {
            this.paperForm.cid = c.cid;
            break;
        }
        await this.getSchool();
        for (const s of this.schoolList as any) {
            this.paperForm.sid = s.sid;
            break;
        }
        this.getClass();
        this.gradeList = await TeCommon.getGrade();
    }

    private async getLevel() {
        this.paperForm.level = '';
        this.paperForm.unit = '';
        const grade = this.paperForm.grade;
        this.levelList = await TeCommon.getLevel(grade);
        this.unitList = {};
    }

    private async getUnit() {
        this.paperForm.unit = '';
        this.unitList = await TeCommon.getUnit();
    }

    // 取得 該縣市的分校列表 
    private async getSchool() {
        this.paperForm.sid = '';
        this.paperForm.clid = '';
        const cid = this.paperForm.cid;
        this.schoolList = await TeCommon.getTESchool(cid, this.paperForm.tid);
        this.classList = [];
    }

    // 取得 該分校的班級列表
    private async getClass() {
        this.paperForm.clid = '';
        const sid = this.paperForm.sid;
        this.classList = await TeCommon.getTEClass(sid, this.paperForm.tid);
    }

    public async validateAndSubmit() {
        validate(this.paperForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        name: '',
                        date: '',
                        cid: '',
                        sid: '',
                        clid: '',
                        unit: '',
                        level: '',
                        grade: '',
                        num: '',
                        active: '',
                    };

                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const errNo = await HomeworkModel.teAddPaper(this.paperForm);
                    switch (errNo) {
                        case ErrorCode.InvalidToken:
                            TeCommon.logout();
                            break;
                        case ErrorCode.Success:
                            this.doubleClick = false;
                            this.$router.push("/teacher/homework/paper");
                            break;
                        default:
                            this.lightbox.errorMsg = ErrorMessage[errNo];
                            this.lightbox.showLB = true;
                            this.doubleClick = false;
                            break;
                    }
                }

            }
        )
    }

    private closeLB() {
        this.lightbox.showLB = false;
    }
}